.container, .password{
    width: 100%;
}
.container p, .password p{
    margin: 0;
    font-size: 15px;
    font-weight: 500;
}
.container input, .container select{
    height: 45px;
    width: 100%;
    margin-top: 10px;
    box-sizing: border-box;
    padding-left: 10px;
    border: 0.5px solid #b5b5b5;
    background-color: #fbfbfb;
    border-radius: 7px;
}
.container input:focus, .container select:focus{
    outline: none;
    border-color: var(--purple);
}
/************ password stylings *****************/
.password_main{
    height: 45px;
    width: 100%;
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 45px;
    border: 0.5px solid #b5b5b5;
    background-color: #fbfbfb;
    border-radius: 7px;
}
.password_main input{
    width: 100%;
    height: 100%;
    background-color: transparent;
    padding-left: 10px;
    box-sizing: border-box;
    border: none;
}
.password_main input:focus{
    outline: none;
}
.password_main:focus-within{
    border-color: var(--purple);
}
.password_main div{
    height: 100%;
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}