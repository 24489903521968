.container{
    height: calc(100vh - 70px);
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.canva{
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #dfdfdf;
}
.canva_backdrop{
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
    color: #fff;
}
.canva_backdrop h2 span{
    color: #6db0fd;
}
.formContainer{
    width: 450px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 100px;
}
.formContainer h2{
    margin: 0;
}
.intro_text{
    margin: 0;
    margin-top: 7px;
    opacity: 0.7;
}
.inputsContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
}
.formContainer button{
    height: 50px;
    width: 100%;
    background-color: var(--purple);
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 40px;
}
.loginLink{
    margin: 0;
    color: #383838;
    cursor: pointer;
    margin-top: 20px;
}
.loginLink span{
    color: var(--purple);
}

@media (max-width: 700px) {
    .container{
        display: block;
    }
    .canva{
        display: none;
    }
    .formContainer{
        width: calc(100% - 30px);
    }
}