.container{
    height: 40px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 40px;
    align-items: center;
    gap: 10px;
}
.container h2{
    font-size: 20px;
    margin: 0;
    color: #000;
    font-weight: 600;
}
.menu{
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: none;
}
@media (max-width: 900px) {
    .menu{
        display: flex;
    }
}