.container{
    height: fit-content;
    width: 100vw;
    background-color: #fff;
    padding: 50px 20px;
    box-sizing: border-box;
}
.container h1{
    color: #000;
    margin: 0;
    margin-bottom: 15px;
    text-align: center;
}
.container h1 span{
    color: var(--purple);
}
.mainContainer{
    width: 700px;
    height: 500px;
    overflow: hidden;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
}
.Item{
    width: 100%;
    border: 0.5px solid #b5b5b5;
    display: grid;
    grid-template-columns: 150px 150px 1fr;
    gap: 20px;
    align-items: center;
    border-radius: 5px;
    padding: 15px 15px;
    box-sizing: border-box;
    animation: moveUp 5s infinite;
    background-color: #fff;
}
.button{
    display: flex;
    height: 35px;
    width: 100%;
    background-color: rgb(70, 207, 241);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #fff;
    border-radius: 7px;
    font-size: 14px;
    font-weight: 500;
}
.amount{
    font-weight: 500;
}
  
@keyframes moveUp {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100%);
    }
}
@media (max-width: 500px) {
    .mainContainer{
        width: 100%;
    }
    .Item{
        display: flex;
        flex-direction: row;
    }
    .button{
        padding: 0 10px;
    }
}