.container{
    height: 100vh;
    width: 100%;
    background-color: #fff;
    overflow-y: scroll;
    padding: 20px;
    box-sizing: border-box;
}
.top{
    width: 100%;
    margin-top: 20px;
}
.top h3{
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: #2a2a2a;
}
.top span{
    color: rgb(0, 204, 0);
}
.top p{
    margin: 0;
    font-size: 14px;
    opacity: 0.7;
    margin-top: 7px;
}
.MainWallets{
    width: 700px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 50px;
    margin-bottom: 100px;
}
.MainWallets_button, .edit_button{
    height: 40px;
    width: fit-content;
    padding: 0 30px;
    background-color: var(--purple);
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    border-radius: 7px;
}
.edit_button{
    margin-right: auto;
    margin-left: auto;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.MainWallets_main{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 20px;
}
@media (max-width: 500px) {
    .MainWallets{
        width: 100%;
    }
    .MainWallets_main{
        display: flex;
        flex-direction: column;
    }
}
.walletItem{
    height: fit-content;
    width: 100%;
    background-color: #f7f7f7;
    border-radius: 10px;
    padding: 15px;
    box-sizing: border-box;
    overflow: hidden;
}
.active_walletItem .wallet_top{
    color: var(--purple);
}
.wallet_top{
    display: flex;
    align-items: center;
    gap: 20px;
}
.wallet_top img{
    height: 35px;
    width: 35px;
    border-radius: 40px;
    object-fit: cover;
}
.wallet_top p{
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}
.wallet_address{
    padding: 15px 0;
    margin: 0;
}
.back{
    height: 35px;
    width: fit-content;
    padding: 0 20px;
    background-color: #f9f9f9;
    border-radius: 7px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: red;
}