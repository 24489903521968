.container{
    width: 100%;
    margin-top: 30px;
}
.container h2{
    font-size: 20px;
    margin: 0;
    font-weight: 500;
}
.mainContainer{
    width: 100%;
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    align-items: center;
}
.Plan{
    height: fit-content;
    width: 100%;
    background-color: #fff;
    border: 0.5px solid #c4c4c4;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
}
.top{
    display: flex;
    gap: 15px;
    width: 100%;
    align-items: center;
}
.icon{
    height: 40px;
    width: 40px;
    border-radius: 50px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}
.name{
    font-size: 18px;
    font-weight: 500;
}
.intro{
    width: 100%;
    margin-top: 15px;
    font-size: 14px;
    opacity: 0.7;
}
.main{
    width: 100%;
    margin-top: 15px;
}
.pole{
    height: 10px;
    width: 100%;
    border-radius: 10px;
    background-color: #f5f5f5;
    overflow: hidden;
}
.pole div{
    height: 100%;
    border-radius: 10px;
    cursor: pointer;
}
.amount{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    padding-top: 7px;
}
.amount div:nth-child(2){
    display: flex;
    justify-content: flex-end;
}
.bottom{
    width: 100%;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 10px;
    align-items: center;
}
.bottom button{
    height: 35px;
    width: 100px;
    background-color: #fff;
    border: 1px solid var(--purple);
    color: var(--purple);
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
}
.investors{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.investors p{
    margin: 0;
    font-size: 15px;
    font-weight: 500;
}
.images{
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.images img{
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background-color: var(--purple);
    border: 3px solid #fff;
    object-fit: cover;
}
.images img:nth-child(1){
    position: relative;
    right: -20px;
}
.images img:nth-child(2){
    position: relative;
    right: -10px;
}
.images img:nth-child(3){
    z-index: 10;
}
@media (max-width: 1000px) {
    .mainContainer{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}