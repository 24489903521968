.contianer{
    width: 100vw;
    text-align: center;
    padding-top: 100px;
}
.contianer h1{
    color: #000;
    margin: 0;
    margin-bottom: 15px;
}
.contianer h1 span{
    color: var(--purple);
}
.intro{
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    opacity: 0.7;
}
.mainContainer{
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
}
.service{
    width: 340px;
}
.service img{
    height: 400px;
    width: 100%;
    object-fit: cover;
}
.title{
    height: 40px;
    width: fit-content;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -20px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: 0.5px solid #d3d3d3;
    font-size: 18px;
    font-weight: 600;
}
.details{
    opacity: 0.7;
    margin: 0;
    line-height: 150%;
    font-size: 14px;
}
.link{
    color: var(--purple);
    margin: 0;
    margin-top: 20px;
    cursor: pointer;
}

@media (max-width: 500px) {
    .contianer{
        width: calc(100% - 30px);
        margin-right: auto;
        margin-left: auto;
    }
    .contianer h1{
        font-size: 23px;
    }
    .intro{
        margin: 0;
        font-size: 14px;
        line-height: 150%;
    }
    .mainContainer{
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
    }
    .service{
        width: 100%;
    }
}