.container{
    width: 100vw;
    background-color: #fff;
    padding: 50px 0;
}
.container h1{
    color: #000;
    margin: 0;
    margin-bottom: 15px;
    text-align: center;
}
.container h1 span{
    color: var(--purple);
}
.mainContainer{
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
}
.Item{
    height: 250px;
    width: 270px;
    background-color: #000;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
}
.icon{
    color: var(--purple);
}
.Item h3{
    margin: 0;
    margin-top: 20px;
    margin-bottom: 7px;
}
.Item p{
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.8;
}
@media (max-width: 500px) {
    .container{
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
    }
    .container h1{
        font-size: 23px;
    }
    .intro{
        margin: 0;
        font-size: 14px;
        line-height: 150%;
    }
    .mainContainer{
        flex-direction: column;
        -ms-grid-row-span: 20px;
    }
    .Item{
        width: 100%;
    }
    .middle{
        transform: scale(1);
    }
}