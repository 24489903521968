.container{
    height: fit-content;
    width: 100vw;
    background-color: #131313;
}
.newsLetter{
    text-align: center;
    color: #fff;
    padding: 50px 0;
}
.newsLetter h1{
    margin: 0;
}
.inputContainer{
    height: 45px;
    width: 550px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    margin-bottom: 50px;
}
.inputContainer input{
    width: 400px;
    height: 100%;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 7px;
    padding-left: 20px;
    font-size: 15px;
    color: #fff;
}
.inputContainer input:focus{
    outline: none;
    border: 1px solid var(--purple);
}
.inputContainer button{
    height: 100%;
    width: 150px;
    background-color: var(--purple);
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    border-radius: 7px;
    letter-spacing: 1px;
}
.copyright{
    color: #fff;
    opacity: 0.5;
    font-size: 14px;
    margin: 0;
    margin-top: 50px;
}
@media (max-width: 500px) {
    .container{
        padding: 0 20px;
        box-sizing: border-box;
    }
    .newsLetter h1{
        font-size: 23px;
    }
    .inputContainer{
        width: 100%;
    }
}
.contact{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    text-align: left;
}
.phone, .map, .mail{
    height: 30px;
    width: 35px;
    background-color: rgba(255, 253, 130, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 5px;
}
.map{
    background-color: rgba(101, 101, 255, 0.3);
}
.mail{
    background-color: rgba(255, 255, 0, 0.271);
}
.contact p{
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
}