.container{
    height: 100vh;
    width: 100%;
    background-color: #fff;
    overflow-y: scroll;
    padding: 20px;
    box-sizing: border-box;
}
.top{
    width: 100%;
    margin-top: 20px;
}
.top h3{
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: #2a2a2a;
}
.top span{
    color: rgb(0, 204, 0);
}
.top p{
    margin: 0;
    font-size: 14px;
    opacity: 0.7;
    margin-top: 7px;
}
/********** Transactions stylings ************/
.transactions{
    width: 100%;
    margin-top: 40px;
}
.transactions_top{
    display: grid;
    grid-template-columns: 1fr 150px;
    align-items: center;
}
.transactions_top h2{
    font-size: 20px;
    margin: 0;
    font-weight: 500;
}
.transactions_top button{
    height: 40px;
    width: 100%;
    background-color: var(--purple);
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 7px;
}
.transactions_container{
    width: 100%;
    border-radius: 20px;
    border: 0.5px solid #c4c4c4;
    margin-top: 25px;
    overflow: hidden;
}
.transactions_header{
    height: 50px;
    width: 100%;
    border-bottom: 0.5px solid #c4c4c4;
    padding: 0 20px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 30px 1fr 150px 150px 150px 150px;
    align-items: center;
    gap: 20px;
}
.transactions_header div{
    font-size: 14px;
    opacity: 0.7;
}
.transactions_header_date{
    display: flex;
    justify-content: flex-end;
}
.transactions_header_amount, .transactions_header_status{
    display: flex;
}
@media (max-width: 1200px) {
    .transactions_header{
        grid-template-columns: 30px 1fr 150px 150px 150px;
    }
    .transactions_header_type{
        display: none;
    }
}
.empty_transactions{
    height: 400px;
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.empty_transactions img{
    height: 150px;
}
.empty_transactions_title{
    font-size: 16px;
    margin: 0;
    margin-top: 15px;
    font-weight: 600;
}
.empty_transactions_details{
    font-size: 14px;
    margin: 0;
    margin-top: 7px;
    opacity: 0.6;
}
@media (max-width: 1000px) {
    .transactions_header{
        grid-template-columns: 30px 1fr 150px 150px;
    }
    .transactions_header_type{
        display: none;
    }
    .transactions_header_date{
        display: none;
    }
    .transactions_header_status{
        justify-content: flex-end;
    }
    .transactions_header_amount{
        justify-content: flex-end;
    }
}
@media (max-width: 600px) {
    .transactions_header{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .transactions_header_sn{
        display: none;
    }
}