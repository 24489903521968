.container{
    height: calc(100vh - 70px);
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.canva{
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #dfdfdf;
}
.canva_backdrop{
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
    color: #fff;
}
.formContainer{
    width: 450px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 100px;
}
.formContainer h2{
    margin: 0;
}
.canva_backdrop h2 span{
    color: #6db0fd;
}
.intro_text{
    margin: 0;
    margin-top: 7px;
    opacity: 0.7;
}
.inputsContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
}
.formContainer button{
    height: 50px;
    width: 100%;
    background-color: var(--purple);
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 40px;
}
.loginLink{
    margin: 0;
    color: #383838;
    cursor: pointer;
    margin-top: 20px;
}
.loginLink span{
    color: var(--purple);
}

/*********** Email Modal styling *************/
.emailModal{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    align-items: center;
    justify-content: center;
}
.emailModal_main{
    width: 500px;
    background-color: #fff;
    border-radius: 15px;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
}
.emailModal_main img{
    height: 150px;
}
.emailModal_title{
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}
.EmailModal_text{
    font-size: 15px;
    color: #6f6f6f;
    margin: 0;
    margin-top: 7px;
    margin-bottom: 20px;
}
.EmailModal_text span{
    font-weight: 500;
    color: #000;
}
.emailModal_main button{
    height: 45px;
    width: 200px;
    background-color: var(--purple);
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    border-radius: 10px;
}
.emailModal_close{
    height: 30px;
    width: 30px;
    background-color: #eeeeee;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
}
.emailModal_close:hover{
    background-color: #dedede;
}

@media (max-width: 700px) {
    .container{
        display: block;
    }
    .canva{
        display: none;
    }
    .formContainer{
        width: calc(100% - 30px);
    }
    .emailModal_main{
        width: calc(100% - 30px);
        margin-right: auto;
        margin-left: auto;
    }
}