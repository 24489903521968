@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
}

:root{
  --purple: #0166DA;
  --purpleLight: #1478ea;
}