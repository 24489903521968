.backdrop{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
}
.container{
    width: 500px;
    position: relative;
}
@media (max-width: 500px) {
    .container{
        width: calc(100% - 30px);
    }
}
.close{
    height: 40px;
    width: 40px;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    position: absolute;
    right: 0;
}
.mainContainer{
    width: 100%;
    margin-top: 70px;
    background-color: #000;
}