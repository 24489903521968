.coursecontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 70px);
    background-color: #555555;
}

.buttoncontainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.backbutton, .forwardbutton {
    font-size: 18px;
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    background-color: #0077cc;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.backbutton:hover, .forwardbutton:hover {
    background-color: #005c99;
}

.framecontainer {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.mainContainer{
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.51);
    display: flex;
    align-items: center;
    padding: 0 100px;
    box-sizing: border-box;
    color: #fff;
}
.mainContainer .items_container{
    height: fit-content;
    width: fit-content;
}
.mainContainer h1{
    font-size: 50px;
    animation: slidein 0.8s ease forwards;
}
@keyframes slidein {
    0%{transform: translateY(-100px); opacity: 0;}
    100%{transform: translateY(0px); opacity: 1;}
}
.mainContainer p{
    font-size: 18px;
    font-weight: 500;
    line-height: 170%;
    margin: 0;
    max-width: 70%;
    animation: slideUp 0.8s ease forwards;
}
.mainContainer button{
    height: 50px;
    padding: 0 50px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    background-color: var(--purple);
    color: #fff;
    border: none;
    border-radius: 10px;
    margin-top: 50px;
    animation: slideUp 0.8s ease forwards;
}
@keyframes slideUp {
    0%{transform: translateY(100px); opacity: 0;}
    100%{transform: translateY(0px); opacity: 1;}
}
.backButton{
    height: 50px;
    width: 50px;
    border-radius: 30px;
    border: 1px solid #fff;
    cursor: pointer;
    position: absolute;
    left: 20px;
    top: calc(100%/2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}
.nextButton{
    right: 20px;
    height: 50px;
    width: 50px;
    border-radius: 30px;
    border: 1px solid #fff;
    cursor: pointer;
    position: absolute;
    top: calc(100%/2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}
.videoPlay{
    height: 50px;
    width: 50px;
    background-color: #fff;
    border-radius: 30px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--purple);
    animation: echo 3.5s linear infinite;
    cursor: pointer;
    display: none;
}
@keyframes echo {
    0% {
      box-shadow: none;
    }
    50% {
      box-shadow: 0 0 0px 15px rgba(255, 255, 255, 0.451);
    }
    100% {
      box-shadow: none;
    }
}
  @media (max-width: 700px) {
    .backButton, .nextButton{
        display: none;
    }
    .mainContainer{
        padding: 0 20px;
        text-align: center;
        background-color: rgba(0, 0, 255, 0.2);
    }
    .mainContainer h1{
        font-size: 30px;
    }
    .mainContainer p{
        max-width: 100%;
        font-size: 14px;
    }
    .videoPlay{
        display: flex;
    }
}