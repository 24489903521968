.container{
    height: 100vh;
    width: 100%;
    background-color: #fff;
    overflow-y: scroll;
    padding: 20px;
    box-sizing: border-box;
}
.top{
    width: 100%;
    margin-top: 20px;
}
.top h3{
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: #2a2a2a;
}
.top span{
    color: rgb(0, 204, 0);
}
.top p{
    margin: 0;
    font-size: 14px;
    opacity: 0.7;
    margin-top: 7px;
}
.mainContainer{
    width: 400px;
    border: 0.5px solid #cacaca;
    margin-right: auto;
    margin-left: auto;
    margin-top: 50px;
    border-radius: 15px;
    padding: 20px;
    box-sizing: border-box;
}
.mainContainer h3{
    font-size: 18px;
    margin: 0;
    font-weight: 500;
    text-align: center;
}
.form_container{
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.PaymentMethods h3{
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    text-align: left;
}
.PaymentMethods_main{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin-top: 10px;
}
.PaymentMethod{
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 20px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 7px;
    background-color: #f9f9f9;
    color: #393939;
}
.active_paymentmethod{
    background-color: rgb(232, 232, 255);
    color: var(--purple);
}
.PaymentMethod img{
    height: 25px;
    width: 25px;
    border-radius: 20px;
    object-fit: cover;
}
.PaymentMethod p{
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}
.mainContainer button{
    height: 45px;
    width: 100%;
    background-color: var(--purple);
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    margin-top: 30px;
}
@media (max-width: 600px) {
    .mainContainer{
        width: 100%;
    }
}