.container{
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 300px 1fr;
    background-color: #fff;
}
.SideDrawer{
    height: 100%;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    border-right: 0.5px solid #d0d0d0;
    position: relative;
}
.SideDrawer .logo, .logo2{
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #000;
    padding-top: 4px;
}
.SideDrawer .logo span, .logo2 span{
    color: var(--purple);
}
.logo2{
    text-align: right ;
}
.linksContainer{
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.profile{
    width: 100%;
    margin-top: 50px;
    text-align: center;
    cursor: pointer;
}
.profile img{
    height: 90px;
    width: 90px;
    border-radius: 90px;
    object-fit: cover;
    background-color: var(--purple);
}
.profile h3{
    font-size: 16px;
    margin: 0;
    font-weight: 500;
    margin-top: 12px;
}
.profile p{
    margin: 0;
    font-size: 14px;
    margin-top: 5px;
    opacity: 0.7;
}
.Link{
    height: 45px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    background-color: #fff;
    border-radius: 7px;
    font-size: 16px;
    font-weight: 500;
    padding: 0 15px;
    box-sizing: border-box;
    color: #585858;
    cursor: pointer;
}
.active{
    background-color: rgba(1, 102, 218, 0.15);
    color: #0166DA;
}

.Link:hover{
    background-color: #f5f5f5;
}
.active:hover{
    background-color: rgba(1, 102, 218, 0.15);
    color: #0166DA;
}
.logout_button{
    height: 50px;
    width: 100%;
    background-color: #f8f8f8;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    padding: 0 20px;
    box-sizing: border-box;
    color: red;
    cursor: pointer;
    border-top: 0.5px solid #e2e2e2;
    font-weight: 500;
}
.logout_button2{
    height: 45px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    background-color: #fff;
    border-radius: 7px;
    font-size: 16px;
    font-weight: 500;
    padding: 0 15px;
    box-sizing: border-box;
    cursor: pointer;
    color: red;
}
/******** Responsive styling **********/
@media (max-width: 900px) {
    .container{
        grid-template-columns: 1fr;
    }
    .SideDrawer{
        display: none;
    }
}

/********* Mobile Side drawer **********/
.mobile_sideDrawer{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 150;
    display: grid;
    grid-template-columns: 1fr 300px;
    background-color: rgba(0, 0, 0, 0.4);
}
.mobile_sideDrawer_close{
    height: 100%;
    width: 100%;
}
.mobile_sideDrawer_main{
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 15px;
    box-sizing: border-box;
    position: relative;
    animation: slideInDrawer 0.5s ease forwards;
}
@keyframes slideInDrawer {
    0%{transform: translateX(350px);}
    100%{transform: translateX(0);}
}

.loader_container{
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}