.container{
    height: 100vh;
    width: 100%;
    background-color: #fff;
    overflow-y: scroll;
    padding: 20px;
    box-sizing: border-box;
}
.top{
    width: 100%;
    margin-top: 20px;
}
.top h3{
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: #2a2a2a;
}
.top span{
    color: rgb(0, 204, 0);
}
.top p{
    margin: 0;
    font-size: 14px;
    opacity: 0.7;
    margin-top: 7px;
}
.InsufficientBalance{
    height: 300px;
    width: 100%;
    padding-top: 100px;
    text-align: center;
}
.InsufficientBalance img{
    width: 100px;
}
.InsufficientBalance h3{
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    margin-top: 10px;
}
.InsufficientBalance p{
    font-size: 14px;
    margin: 0;
    margin-top: 7px;
    opacity: 0.7;
}
.InsufficientBalance button{
    height: 40px;
    width: fit-content;
    padding: 0 25px;
    margin-top: 50px;
    background-color: var(--purple);
    color: #fff;
    font-weight: 500;
    border-radius: 7px;
    border: none;
    font-size: 15px;
}
/**************** Withdraw form styles ****************/
.WithdrawalForm{
    height: fit-content;
    width: 500px;
    border: 0.5px solid #8b8b8b;
    margin-right: auto;
    margin-left: auto;
    margin-top: 50px;
    border-radius: 20px;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 100px;
}
.WithdrawalForm h3{
    font-size: 18px;
    margin: 0;
    font-weight: 500;
    text-align: center;
}
.form_container{
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.walletAddresses{
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}
.walletAddresses p{
    margin: 0;
}
.walletItem, .active_walletItem{
    width: 100%;
    background-color: #f7f7f7;
    border-radius: 10px;
    padding: 15px;
    box-sizing: border-box;
    overflow: hidden;
}
.active_walletItem{
    background-color: rgb(232, 232, 255);
}
.active_walletItem .wallet_top{
    color: var(--purple);
}
.wallet_top{
    display: flex;
    align-items: center;
    gap: 20px;
}
.wallet_top img{
    height: 35px;
    width: 35px;
    border-radius: 40px;
    object-fit: cover;
}
.wallet_top p{
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}
.wallet_address{
    padding: 15px 0;
}
.WithdrawalForm button{
    height: 45px;
    width: 100%;
    background-color: var(--purple);
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    margin-top: 30px;
}
@media (max-width: 500px) {
    .WithdrawalForm{
        width: 100%;
    }
}