.container{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.3);
    display: grid;
    grid-template-columns: 1fr 300px;
    position: fixed;
    top: 0;
    left: 0;
}
.close{
    height: 100%;
    width: 100%;
}
.main{
    height: 100%;
    width: 100%;
    background-color: #fff;
    animation: slideIn 0.5s ease forwards;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
}
@keyframes slideIn {
    0%{transform: translateX(300px);}
    100%{transform: translateX(0px);}
}
.links{
    height: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 100px;
}
.links p{
    margin: 0;
    font-weight: 600;
    text-align: right;
}
.copyright{
    position: absolute;
    bottom: 100px;
    right: 20px;
    text-align: right;
    font-size: 14px;
    opacity: 0.7;
}