/********* Deposit Modal stylings **********/
.backdrop{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
}
.backdrop_main{
    width: 400px;
    background-color: #fff;
    margin-right: auto;
    margin-left: auto;
    border-radius: 15px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    margin-top: 100px;
    animation: drop 0.5s ease forwards;
}
@media (max-width: 600px) {
    .backdrop_main{
        width: calc(100% - 30px);
    }
}
@keyframes drop {
    0%{transform: translateY(-200px);}
    100%{transform: translateY(0);}
}
.backdrop_close{
    height: 35px;
    width: 35px;
    border-radius: 20px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
}
.top{
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}
.intro{
    font-size: 14px;
    color: #4e4e4e;
    margin-top: 20px;
}
.intro span{
    font-weight: 500;
    color: #000;
}
.details{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin-top: 20px;
}
.Item{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    font-size: 14px;
}
.details_title{
    color: #434343;
}
.details_main{
    display: flex;
    justify-content: flex-end;
    text-align: right;
    color: #000;
    font-weight: 500;
}
.backdrop_main button{
    height: 45px;
    width: 100%;
    background-color: var(--purple);
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    margin-top: 30px;
}
.done_container{
    text-align: center;
}
.done_container img{
    height: 200px;
}
.done_title{
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    margin-top: 10px;
}
.done_main{
    font-size: 14px;
    color: #000;
    opacity: 0.7;
    margin: 0;
    margin-top: 7px;
}