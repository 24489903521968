.container{
    height: fit-content;
    width: 1100px;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    align-items: center;
    padding-top: 100px;
}
.aboutImg{
    width: 100%;
}
.mainContainer h1{
    margin: 0;
    margin-bottom: 20px;
    color: var(--purple);
}
.mainContainer h1 span{
    color: #000;
}
.mainContainer p{
    margin: 0;
    line-height: 150%;
}
.mainContainer button{
    margin-top: 20px;
    height: 45px;
    padding: 0 30px;
    cursor: pointer;
    border: none;
    color: #fff;
    background-color: var(--purple);
    font-weight: 500;
    border-radius: 7px;
    font-size: 15px;
}
@media (max-width: 500px) {
    .container{
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: calc(100% - 30px);
    }
    .mainContainer h1{
        font-size: 23px;
    }
    .mainContainer p{
        margin: 0;
        font-size: 14px;
        line-height: 150%;
    }
}