.backdrop{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}
.container{
    width: 500px;
    background-color: #fff;
    margin-right: auto;
    margin-left: auto;
    margin-top: 100px;
    border-radius: 15px;
    position: relative;
}
.close{
    height: 35px;
    width: 35px;
    border-radius: 30px;
    background-color: #f5f5f5;
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.top{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    padding-top: 20px;
}
.main_top{
    height: fit-content;
    width: 100%;
    margin-top: 30px;
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 0.5px solid #cbcbcb;
}
.icon, .icon_withdraw{
    height: 100px;
    width: 100px;
    background-color: cyan;
    border-radius: 60px;
    margin-right: auto;
    margin-left: auto;
    border: 5px solid #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2e2e2e;
}
.icon_withdraw{
    background-color: rgb(255, 94, 94);
    color: #fff;
}
.main_top h2{
    font-size: 25px;
    font-weight: 600;
    margin: 0;
    margin-top: 15px;
}
.main_container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;
}
.detail{
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: 20px;
}
.detail div:nth-child(1){
    font-size: 14px;
    opacity: 0.7;
}
.detail div:nth-child(2){
    font-size: 16px;
    font-weight: 500;
    text-align: end;
    display: flex;
    justify-content: flex-end;
}
.pending, .failed, .success{
    height: 25px;
    width: fit-content;
    border-radius: 20px;
    border: 1px solid rgb(0, 221, 0);
    background-color: rgb(191, 254, 191);
    font-size: 14px;
    font-weight: 500;
    padding: 0 15px;
    color: rgb(0, 221, 0);
    display: flex;
    align-items: center;
    justify-content: center;
}
.failed{
    color: red;
    background-color: rgba(255, 0, 0, 0.4);
    border-color: red;
}
.success{
    color: blue;
    background-color: rgba(0, 255, 255, 0.502);
    border-color: blue;
}
.main_container button{
    height: 45px;
    width: 100%;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    background-color: var(--purple);
    border: none;
    border-radius: 10px;
    margin-top: 10px;
}
@media (max-width: 500px) {
    .container{
        width: calc(100vw - 30px);
    }
}