.container{
    width: 100%;
    border-bottom: 0.5px solid #c4c4c4;
    padding: 15px 20px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 30px 1fr 150px 150px 150px 150px;
    align-items: center;
    gap: 20px;
    font-size: 16px;
    cursor: pointer;
}
.container:hover{
    background-color: #f8fcff;
}
.date{
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    opacity: 0.8;
}
.amount{
    font-weight: 600;
}
.pending, .failed, .success{
    height: 25px;
    background-color: red;
    width: fit-content;
    border-radius: 20px;
    border: 1px solid rgb(0, 221, 0);
    background-color: rgb(191, 254, 191);
    font-size: 14px;
    font-weight: 500;
    padding: 0 15px;
    color: rgb(0, 221, 0);
    display: flex;
    align-items: center;
    justify-content: center;
}
.failed{
    color: red;
    background-color: rgba(255, 0, 0, 0.4);
    border-color: red;
}
.success{
    color: blue;
    background-color: rgba(0, 255, 255, 0.502);
    border-color: blue;
}
.transaction{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}
.transaction img{
    height: 35px;
    width: 35px;
    border-radius: 20px;
    object-fit: cover;
}

.type{
    text-transform: capitalize;
}

@media (max-width: 1200px) {
    .container{
        grid-template-columns: 30px 1fr 150px 150px 150px;
    }
    .type{
        display: none;
    }
}
@media (max-width: 1000px) {
    .container{
        grid-template-columns: 30px 1fr 150px 150px;
    }
    .type{
        display: none;
    }
    .date{
        display: none;
    }
    .status{
        justify-self: flex-end;
    }
    .amount{
        justify-self: flex-end;
    }
}
@media (max-width: 600px) {
    .container{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .sn{
        display: none;
    }
}