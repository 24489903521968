.container{
    height: fit-content;
    width: 100vw;
    background-color: #fff;
    padding: 50px 20px;
    box-sizing: border-box;
}
.container h1{
    color: #000;
    margin: 0;
    margin-bottom: 15px;
    text-align: center;
}
.container h1 span{
    color: var(--purple);
}
.mainContainer{
    margin-top: 20px;
}
.Item{
    height: fit-content;
    width: 500px;
    border: 0.5px solid #ccc;
    margin-right: auto;
    margin-left: auto;
    border-radius: 15px;
    padding: 20px;
    box-sizing: border-box;
    display: none;
}
.active{
    display: block;
    animation: slideIn 0.5s ease forwards;
}
/* @keyframes slideIn {
    0%{transform: translateX(-100px);}
    100%{transform: translateX(0);}
} */
.review {
    margin: 0;
    font-size: 14px;
    opacity: 0.7;
}
.Item div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
}
.Item img{
    height: 40px;
    width: 40px;
    border-radius: 22px;
    object-fit: cover;
}
.name{
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}
.Tracker{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}
.Tracker_item{
    height: 10px;
    width: 10px;
    background-color: #e0e0e0;
    border-radius: 10px;
}
.Tracker_active{
    background-color: var(--purple);
}
@media (max-width: 500px) {
    .Item{
        width: 100%;
    }
}