.container{
    height: fit-content;
    width: 100vw;
    padding: 50px 0px;
    background-color: #fff;
    display: grid;
    grid-template-columns: repeat(3, 200px);
    gap: 20px;
    align-items: center;
    justify-content: center;
}
.planItem{
    width: 200px;
    background-color: #fff;
    box-shadow: 4px 0 20px rgba(0,0,0,0.05);
    border: 0.5px solid #e4e4e4;
    padding: 15px;
    box-sizing: border-box;
}
.blue_planItem{
    background-color: var(--purple);
    color: #fff;
}
.top{
    text-align: center;
    font-size: 35px;
    font-weight: 700;
    color: var(--purple);
    padding: 20px 0;
}
.blue_planItem .top{
    color: #fff;
}
.top span{
    font-size: 18px;
}
.arrow{
    border-top: 1px solid var(--purple);
    text-align: center;
    font-size: 14px;
    padding: 15px 0px;
}
.blue_planItem .arrow{
    border-color: #fff;
}
.plan_lists{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.plan_lists_item{
    display: flex;
    flex-direction: row;
    gap: 15px;
    line-height: 150%;
    color: #767676;
}
.blue_planItem .plan_lists_item{
    color: #fff;
}
.plan_lists_item p{
    margin: 0;
    font-size: 14px;
}
.plan_lists_icon{
    color: var(--purple);
    font-size: 20px;
}
.blue_planItem .plan_lists_icon{
    color: #fff;
}
.planItem button{
    background-color: var(--purple);
    width: 100%;
    height: 40px;
    color: #fff;
    border: none;
    font-weight: 500;
    margin-top: 25px;
    cursor: pointer;
    border-radius: 5px;
}
.blue_planItem button{
    background-color: #fff;
    color: var(--purple);
}
@media (max-width: 500px) {
    .container{
        padding-right: 15px;
        padding-left: 15px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    .planItem{
        width: 100%;
    }
}